import { useEffect, useState } from 'react';
import useScript from 'react-script-hook';

/**
 * Supported 'type' values for DY recommendation context
 *
 * @see https://dy.dev/docs/page-context
 */
export enum DyRecommendationContextType {
	CART_PAGE = 'CART',
	CATEGORY_PAGE = 'CATEGORY',
	HOME_PAGE = 'HOMEPAGE',
	PRODUCT_PAGE = 'PRODUCT',
	OTHER_PAGE = 'OTHER'
}

type DyRecommendationContext = { type: DyRecommendationContextType; data?: any };

/**
 * Accepts a dynamic yield recommendationContext and returns an array with
 * script meta data intended for use with Helmet.  This is used to initiate
 * site personalization campaigns in dynamic yield.
 *
 * @param context - context used for window.DY.recommendationContext
 */
export const useDyRecommendationContext = (context: DyRecommendationContext) => {
	const [dyId, setDyId] = useState<string | null>(null);
	// stringifying context to make sure it is only updated once
	const contextString = JSON.stringify(context);
	// change recommendationContext on DY window object whenever context changes
	useEffect(() => {
		if (window?.DY) {
			window.DY.recommendationContext = context;
		}
	}, [contextString]);
	/**
	 * After navigating off the page using the react router and then return to
	 * that page the recommendations need to be re-rendered as the useScripts
	 * below will not reload the script that already exists.  This effect will
	 * setup the recommendation for the page on mount.  DY documentations also
	 * has notes on DY.API('spa', {...}) for similar situations however
	 * when exploring this option it was not causing a rerender. Long term we
	 * plan to move away from the client implementation in favor of server side.
	 */
	useEffect(() => {
		if (window?.DYO?.setupServerExperiments) {
			window.DYO.setupServerExperiments();
		}
	}, []);

	// determine dyId on mount
	useEffect(() => {
		setDyId(window?.BCOM?.dyId ?? null);
	}, []);

	useScript({
		src: dyId ? `//cdn.dynamicyield.com/api/${dyId}/api_dynamic.js` : null,
		defer: 'true'
	});
	useScript({
		src: dyId ? `//cdn.dynamicyield.com/api/${dyId}/api_static.js` : null,
		defer: 'true'
	});
};
